import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import AuthForm from '../components/AuthForm/AuthForm'
import { resetPassword } from '../helpers/requests'
import Cookies from 'js-cookie'

const ResetPage = props => {
  const [authErrorMessage, setAuthErrorMessage] = useState('')
  const [customerID, setCustomerID] = useState('')
  const [accessToken, setAccessToken] = useState('')
  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    setCustomerID(btoa(`gid://shopify/Customer/${params.get('id')}`))
    setAccessToken(params.get('token'))
  })
  const submitForm = values => {
    resetPassword(customerID, accessToken, values.password).then(res => {
      const { customerReset } = res.data.data
      console.log(res.data)
      if (!customerReset.customerUserErrors.length) {
        const { accessToken, expiresAt } = customerReset.customerAccessToken
        console.log(accessToken, expiresAt)
        const expiryDate = new Date(expiresAt)
        Cookies.set('KEPT_SESS', accessToken, { expires: expiryDate })
        window.location.href = '/'
      }
    })
  }
  return (
    <div>
      <SEO title="Reset Password" />
      <div className="container text-center">
        <div className="row no-gutters pt-50 collection-header">
          <h1 className="ma u-h0">Reset Password</h1>
        </div>
      </div>
      <AuthForm
        type="reset"
        submitForm={submitForm}
        authErrorMessage={authErrorMessage}
        setAuthErrorMessage={setAuthErrorMessage}
      />
    </div>
  )
}

export default ResetPage
